import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
export default function Footer() {
    const { t } = useTranslation();
    return (
        <>
            <footer id="footer" className="fix-bottom footer">

                <div className="container">
                    <div className="row gy-4">
                        <div className="col-lg-5 col-md-12 footer-info">
                            {/* <a href="#" className="">
                    <span>Lopoy</span>
                </a> */}
                            <Link onClick={() => { window.scrollTo(0, 0) }} to='/' class="logo d-flex align-items-center brand">GUFACA</Link>
                            <p>{t("f-about")}</p>
                            <div className="social-links d-flex mt-4">
                                <a href="https://www.facebook.com/profile.php?id=100087182374074" className="facebook" ><i className="bi bi-facebook"></i></a>
                                <a href="https://www.linkedin.com/company/89721747/" className="linkedin" ><i className="bi bi-linkedin"></i></a>
                            </div>
                        </div>

                        <div className="col-lg-2 col-6 footer-links">
                            <h4 className='overr'>{t("liens")}</h4>
                            <ul>
                                <li><Link to="/" onClick={() => { window.scrollTo(0, 0) }}>{t("home")}</Link></li>
                                <li><Link to="/about" onClick={() => { window.scrollTo(0, 0) }}>{t("about")}</Link></li>
                                <li><Link to="/nos-services" onClick={() => { window.scrollTo(0, 0) }}>{t("service")}</Link></li>
                                {/* <li><a href="#">Terms of service</a></li>
                    <li><a href="#">Privacy policy</a></li> */}
                            </ul>
                        </div>

                        <div className="col-lg-2 col-6 footer-links">
                            <h4 className='overr'>{t("service")}</h4>
                            <ul>
                                <li><Link to="/technologie" onClick={() => { window.scrollTo(0, 0) }}>{t("titre-service-n")}</Link></li>

                                <li><Link to="/sourcing" onClick={() => { window.scrollTo(0, 0) }}>{t("titre-service-2")}</Link></li>
                                {/* <li><a href="#">Agriculture</a></li> */}
                                <li><Link to="/negociation" onClick={() => { window.scrollTo(0, 0) }}>{t("titre-service-3")}</Link></li>
                                {/* <li><a href="#">Graphic Design</a></li> */}
                            </ul>
                        </div>

                        <div className="col-lg-3 col-md-12 footer-contact text-center text-md-start">
                            <h4 className='overr'>{t("contact")}</h4>
                            <p>
                                4200 Avenue de Courtai,<br />
                                Montréal, Québec,<br />
                                Canada<br /><br />
                                <strong>Email: </strong><a className='overr' href='mailto:contact@gufaca.com'>contact@gufaca.com</a><br />
                            </p>

                        </div>

                    </div>
                </div>

                <div className="container mt-4">
                    <div className="copyright">
                        &copy; Copyright <strong><span>Gufaca</span></strong>. {t("copyright")}
                    </div>

                </div>

            </footer>

        </>
    );
}