import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.js'
import 'bootstrap-icons/font/bootstrap-icons.css';
import React, { Component } from 'react';
import Home from './Composants/Home/Home';
import About from './Composants/About';
import Contact from './Composants/Contact';
import NotFound from './Composants/NotFound';
import Header from './Composants/Header';
import Footer from './Composants/Footer';
import { BrowserRouter, Routes ,Route } from 'react-router-dom';
import Sourcing from './Composants/Sourcing';
import Negociation from './Composants/Negociation';
import Services from './Composants/Services';
import Technologie from './Composants/Technologie';
class App extends Component {
  render() {
    return (
      <BrowserRouter>
      
        <div>
        <Header />
          <main id="main">
            <Routes>
              <Route path="/" element={<Home/>} />
              <Route path="/about" element={<About />} />
              <Route path="/services" element={<Technologie/>} />
              <Route path="/contact" element={<Contact/>} />
              <Route exact path="*" element={<NotFound/>} />
              <Route path="/technologie" element={<Technologie/>} />
              <Route path="/sourcing" element={<Sourcing/>} />
              <Route path="/negociation" element={<Negociation/>} />
              <Route path="/nos-services" element={<Services/>} />
            </Routes>
          </main>
          <Footer />
        </div>
      </BrowserRouter >
    );
  }
}

export default App;
