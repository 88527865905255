import React, { useRef, useState } from "react";
import "swiper/css/navigation";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
// import required modules
import "swiper/css";
import "swiper/css/navigation";
import { Autoplay, Navigation } from "swiper";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function Slide() {
    const { t } = useTranslation();
    return (
        <div>
            <Swiper
                spaceBetween={30}
                centeredSlides={true}
                autoplay={{
                    delay: 5000,
                    disableOnInteraction: false,
                }}
                pagination={{
                    clickable: true,
                }}
                navigation={false}
                modules={[Autoplay, Navigation]}
                className="mySwiper"
            >
                <SwiperSlide>


                    <div class="slider_area">
                        <div class="slider_active owl-carousel">
                            <div class="single_slider d-flex align-items-center slider_bg_1 overlay2 call-to-action" style={{ backgroundImage: `url('https://i.ibb.co/58fp76C/application-mobile.webp')` }}>
                                <div class="container" data-aos="zoom-out">

                                    <div class="row justify-content-center">
                                        <div class="col-lg-8 text-center">
                                            <h3 style={{ color: "white" }}>{t("call-to-action-1")}</h3>
                                            {/* <p>{ t('call-to-action-t1')}</p> */}
                                            <div className="text-center">
                                                <Link to="/technologie" onClick={() => { window.scrollTo(0, 0) }} class="boxed-btn3 m-2">{t("info")}</Link>
                                                <Link to="/contact" onClick={() => { window.scrollTo(0, 0) }} class="boxed-btn3-white"> <i class="bi bi-telephone-fill"></i>
                                                    {t("contact")}</Link>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div class="slider_area">
                        <div class="slider_active owl-carousel">
                            <div class="single_slider d-flex align-items-center slider_bg_2 overlay2 call-to-action" style={{ backgroundImage: `url("https://i.ibb.co/z4DjGVg/sourcing.webp")`, backgroundPosition: "center" }}>
                                <div class="container" data-aos="zoom-out">

                                    <div class="row justify-content-center">
                                        <div class="col-lg-8 text-start">
                                            <h3 className="text-center">{t("call-to-action-2")}</h3>
                                            {/* <p>{ t('call-to-action-t2')}</p> */}

                                           <div className="text-center">
                                           <Link to="/sourcing" onClick={() => { window.scrollTo(0, 0) }} class="boxed-btn3 m-2">{t("info")}</Link>
                                            <Link to="/contact" onClick={() => { window.scrollTo(0, 0) }} class="boxed-btn3-white"> <i class="bi bi-telephone-fill"></i>
                                                {t("contact")}</Link>
                                           </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div class="slider_area">
                        <div class="slider_active owl-carousel">
                            <div class="single_slider d-flex align-items-center slider_bg_2 overlay2 call-to-action" style={{ backgroundImage: `url("https://i.ibb.co/fknS8y2/n-gociation-international2.webp")` }}>
                                <div class="container" data-aos="zoom-out">

                                    <div class="row justify-content-center">
                                        <div class="col-lg-8 text-start">
                                            <h3 className="text-center">{t("call-to-action-3")}</h3>
                                            <div className="text-center">
                                            <Link to="/negociation" onClick={() => { window.scrollTo(0, 0) }} class="boxed-btn3 m-2">{t("info")}</Link>
                                            <Link to="/contact" onClick={() => { window.scrollTo(0, 0) }} class="boxed-btn3-white"> <i class="bi bi-telephone-fill"></i>
                                                {t("contact")}</Link>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
            </Swiper>

        </div>
    );
}
