import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import cookies from 'js-cookie';
import { motion } from 'framer-motion';
import {
  NavLink, Link, useLocation,
} from 'react-router-dom';
import { useRef, useState } from 'react';
function OffcanvasExample() {
  const { t } = useTranslation();
  
  const languages = [
    {
      code: 'en',
      name: 'English',
      country_code: 'gb'
    },
    {
      code: 'fr',
      name: 'Francais',
      country_code: 'fr'
    },

  ]
  const currentLanguageCode = cookies.get('i18next') || 'en'
  const currentLanguage = languages.find(l => l.code === currentLanguageCode);
  const location = useLocation()
  const togg=useRef()
  const toggle=()=>{
    if (window.innerWidth<="767") {
     togg.current.classList.toggle("dropi") 
    }
  }
  const fermer = useRef()
  const reinit = (e) => {
    if (window.innerWidth <= "767") {
      fermer.current.click()
    }
    window.scrollTo(0, 0);

  }
  const reinitp = () => {
    if (window.innerWidth <= "767") {
      fermer.current.click()
      togg.current.classList.toggle('dropi')
    }
    window.scrollTo(0, 0);
  }
  return (
    <>
      <header className="header-area bg-menu sticky-top shadow">

        <div className="top-header-area " >
          <div className="container">
            <div className="row">
              <div className="col-12" >
                <div className="top-header-content d-flex align-items-center justify-content-between">
                  <div className="top-header-meta">
                    <a href="mailto:contact@gufaca.com" data-toggle="tooltip" data-placement="bottom" title="contact@gufaca.com"><i className="text-sub bi bi-envelope" aria-hidden="true"></i> <span>Email: contact@gufaca.com</span></a>
                  </div>
                  <div className="top-header-meta d-flex justify-content-between gap-5 align-items-center">
                    <div className="dropdown">
                      <button className=" btn btn-link text-light dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                        <i className='text-light bi bi-globe  fs-5'></i>
                      </button>
                      <ul className="dropdown-menu"  aria-labelledby="dropdownMenuButton1">
                        <li><span className='dropdown-tem-text ms-3'>{t("language")}</span></li>
                        <hr className='my-1' />
                        {languages.map(({ code, name, country_code }) => (
                          <li key={country_code}>
                            <button className='dropdown-item' onClick={() => i18next.changeLanguage(code)} disabled={code === currentLanguageCode}>
                              <span style={{ opacity: code === currentLanguageCode ? 0.5 : 1 }} className={`flag-icon flag-icon-${country_code} me-2 `}></span>
                              {name}
                            </button>
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div className="d-md-flex d-none  justify-content-evenly align-items-center  ">
                      <div><a href="https://www.facebook.com/profile.php?id=100087182374074" className="facebook"><i className="bi bi-facebook text-light  fs-5"></i></a></div>
                      <div><a href="https://www.linkedin.com/company/89721747/" className="linkedin"><i className="bi bi-linkedin text-light fs-5 "></i></a></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <nav  class="navbar navbar-expand-md bg-dark" aria-label="Offcanvas navbar large" >
          <div class="container-fluid">
            <Link onClick={()=>{window.scrollTo(0,0)}} to='/' class="brand"><img src='https://i.ibb.co/p67bJhr/Gufaca.webp' height="140" width="140"></img></Link>
            <button  class="navbar-toggler bg-light" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar2" aria-controls="offcanvasNavbar2">
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="offcanvas offcanvas-end text-bg-dark" tabindex="-1" id="offcanvasNavbar2" aria-labelledby="offcanvasNavbar2Label">
              <div class="offcanvas-header">
                <Link to="/" onClick={reinit} class="offcanvas-title" id="offcanvasNavbar2Label" style={{ color: "white" }}><img src='/images/Gufaca.webp' height="120" width="120"></img></Link>
                <button ref={fermer} type="button" class="btn-close btn-close-white" data-bs-dismiss="offcanvas" aria-label="Close"></button>
              </div>
              <div class="offcanvas-body">
                <ul class="navbar-nav justify-content-end flex-grow-1 pe-3">
                  <li className="text-menu"><NavLink onClick={reinit} to="/" className={location.pathname === "/" ? "activation myfs" : "text-me myfs"} role="button">{t('home')}</NavLink></li>
                  <li className="text-menu"><NavLink onClick={reinit} to="/about" className={location.pathname === "/about" ? "activation myfs" : "text-me myfs"} role="button">{t('about')}</NavLink></li>
                  <li className="text-menu princ" >
                    <small onClick={toggle} style={{ fontWeight: "lighter" }} to="#" className={location.pathname === "/services" || location.pathname === "/nos-services" || location.pathname === "/technologie" || location.pathname === "/sourcing" || location.pathname === "/negociation" ? "activation mym myfs" : "text-me mym myfs"}>{t('service')}
                    </small>
                    <div ref={togg} className='englobe dropi'  >
                      <ul class="dropdownp" >
                        <li className={location.pathname === "/services" || location.pathname === "/technologie" ? "  " : "null"} role="button"><Link onClick={reinitp} className={location.pathname === "/services" || location.pathname === "/technologie" ? "text-primaôt tot" : "text-dark tot"} to="/technologie">{t("titre-service-n")}</Link></li>
                        <li role="button" className={location.pathname === "/immobilier" ? " " : "null"} ><Link onClick={reinitp} to="/sourcing" className={location.pathname === "/sourcing" ? "text-primary tot" : "text-dark tot"}>{t("titre-service-2")}</Link></li>
                        <li role="button" className={location.pathname === "/negociation" ? " " : "null"}><Link onClick={reinitp} className={location.pathname === "/negociation" ? "text-primary tot" : "text-dark tot"} to="/negociation" >{t("titre-service-3")}</Link></li>
                      </ul>
                    </div>    
                  </li>
                </ul>
                <div className='btn-menu'>
                  <NavLink onClick={reinit} to="/contact" className={location.pathname === "/contact" ? "activation myfs" : "get-st myfs"} role="button" style={{ borderRadius: "10em" }}>{t('contact')}</NavLink>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
}

export default OffcanvasExample;