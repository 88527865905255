import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Transitions from './Transition';


export default function About() {
    const { t } = useTranslation();
    return (
        <Transitions>
        <main id="">
            <div class="breadcrumbs">
                <div class="page-header d-flex align-items-center" style={{ backgroundImage: `url('https://i.ibb.co/09DXM09/page-header.webp')` }}>
                    <div class="container position-relative"> 
                        <div class="row d-flex justify-content-center">
                            <div class="col-lg-12 text-center">
                                <h6>
                                    {t("about")}
                                </h6>
                                <h2>
                                    {t("about-message1-img")}
                                </h2>
                                {/* <p>Odio et unde deleniti. Deserunt numquam exercitationem. Officiis quo odio sint voluptas consequatur ut a odio voluptatem. Sit dolorum debitis veritatis natus dolores. Quasi ratione sint. Sit quaerat ipsum dolorem.</p> */}
                            </div>
                        </div>
                    </div>

                </div>
                <nav>
                    <div class="container">
                        <ol>
                            <li><Link to="/" onClick={()=>{window.scrollTo(0,0)}}>{t("home")}</Link></li>
                            <li>{t("about")}</li>
                        </ol>
                    </div>
                </nav>
            </div>
            <section class="single-service sidebar-page-container pt-lg-5 ps-lg-5 pe-lg-5 pb-0">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12 content-side">
                            <div class="single-service-content mt-4">
                                <div class="content-style-one service-box">
                                    <div class="inner-box">
                                        <div class="mb-5">
                                            <img src="https://i.ibb.co/9pY0M1n/aboutv.webp" alt="about" class="img-fluid mx-auto d-block" />
                                        </div>    
                                        <p className="text-dark mt-1">
                                            {t("paragraphe1-about")}
                                        </p>
                                        <p className="text-dark mt-1">
                                            {t("paragraphe2-about")}
                                        </p>
                                        <p className="text-dark mt-1">
                                            {t("paragraphe3-about")}
                                        </p>
                                        <p className="text-dark mt-1">
                                            {t("paragraphe4-about")}
                                        </p>
                                        <p className="text-dark mt-1">
                                            {t("paragraphe5-about")}
                                        </p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <section id="about" class="about">
                <div class="container" data-aos="fade-up">

                    <div class="row gy-4">
                        <div class=" position-relative align-self-start text-center order-first">
                            <img src="https://i.ibb.co/9pY0M1n/aboutv.webp" alt="about" class="img-fluid" />
                        </div>
                        <div class="col-12 content order-last  ">
                            <h3 className='confondu-header text-center'>{t("about-gufaca")}</h3>
                            <ul>
                                <li data-aos=" fade-up" data-aos-delay="100">
                                    <div>
                                        <h5 className='text-center confondu-header'>{t("titre-service-1")}</h5>
                                        <div class="d-flex justify-content-center align-items-center">
                                            <i class="bi bi-diagram-3 text-white"></i>
                                            <p className='confondu-content text-center'>{t("service-1-contenu")}</p>
                                        </div>
                                    </div>
                                </li>
                                <li data-aos="fade-up" data-aos-delay="200">
                                    <div>
                                        <h5 className='text-center confondu-header'>{t("titre-service-2")}</h5>
                                        <div class="d-flex justify-content-center align-items-center">
                                            <i class="bi bi-fullscreen-exit text-white"></i>
                                            <p className='confondu-content text-center'>{t("service-2-contenu")}</p>
                                        </div>
                                    </div>
                                </li>
                                <li data-aos="fade-up" data-aos-delay="300">
                                    <div>
                                        <h5 className='text-center confondu-header'>{t("titre-service-3")}</h5>
                                        <div class=" d-flex justify-content-center align-items-center">
                                            <i class="bi bi-broadcast text-white"></i>
                                            <p className='confondu-content text-center'></p>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>

                </div>
            </section> */}
            <section id="team" class="team pt-0 main-bg mb-5">
                <div class="container" data-aos="fade-up">
                    <div class="section-header text-white">                    
                        <h2 className='text-light'>
                            {t("about-valeur")}
                       </h2>
                    </div>
                    <div class="row" data-aos="fade-up" data-aos-delay="100">
                        <div class="col-12 pt-5 pb-5 pd-x">
                            <div class="row">
                                <div class="text-center">
                                    <img src="https://i.ibb.co/Y7YMV0X/collaboation.webp" alt="collaboation" height="200" width="200" class="img-fluid" />
                                </div>
                                <div class="text-center">
                                    <h6 className="text-title">
                                    {t("about-believe1-titre")}
                                    </h6>
                                    <p className="text-para">{t("about-believe1-contenu")}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 pt-5 pb-5 pd-x">
                            <div class="row">
                                <div class="text-center">
                                    <img src="https://i.ibb.co/VmtbScr/responsibility.webp" alt="responsibility" height="200" width="200" class="img-fluid"/>
                                </div>
                                <div class="text-center">
                                    <h6 className="text-title">
                                    {t("about-believe2-titre")}
                                    </h6>
                                    <p className="text-para">{t("about-believe2-contenu")} </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 pt-5 pb-5 pd-x">
                            <div class="row">
                                <div class="text-center">
                                    <img src="https://i.ibb.co/CtZWmT7/innovation.webp" alt="innovation" height="200" width="200" class="img-fluid" />
                                </div> 
                                <div class="text-center">
                                    <h6 className="text-title">
                                    {t("about-believe3-titre")}
                                    </h6>
                                    <p className="text-para">{t("about-believe3-contenu")}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </main>
        </Transitions>
    );
}
