import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Transitions from '../Transition';
export default function Landing() {
    const { t } = useTranslation();
    return (
        <main id="main">

            <div class="block-quick-info-2">
                <div class="container">
                    <div class="block-quick-info-2-inner">
                        <div class="row">
                            <div class="col-sm-6 col-md-6 col-lg-4 mb-3 mb-lg-0">
                                <div class="d-flex quick-info-2">
                                    <span class="icon bi bi-houses-fill me-3"></span>
                                    <div class="text">
                                        <strong class="d-block heading">{t("f-adresse")}</strong>
                                        <span class="excerpt">4200 Avenue de Courtai, Montréal, Québec, Canada</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-6 col-lg-4 mb-3 mb-lg-0">
                                <div class="d-flex quick-info-2">
                                    <span class="icon bi bi-envelope-at-fill me-3"></span>
                                    <div class="text">
                                        <strong class="d-block heading">{t("f-mail")}</strong>
                                        <span class="excerpt"><a href="mailto:contact@gufaca.com">contact@gufaca.com</a></span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-6 col-lg-4 mb-3 mb-lg-0">
                                <div class="d-flex quick-info-2">
                                    <span class="icon bi bi-clock-history me-3"></span>
                                    <div class="text">
                                        <strong class="d-block heading">{t("f-time")}</strong>
                                        <span class="excerpt">{t("f-time-d")}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section id="about" class="about">
                <div class="container" data-aos="fade-up">

                    <div class="row gy-4">
                        <div class="col-lg-6 position-relative align-self-start order-lg-last order-first">
                            <img src="https://i.ibb.co/9pY0M1n/aboutv.webp" alt="about" class="img-fluid" />
                        </div>
                        <div class="col-lg-6 content order-last  order-lg-first">
                            <h3 className='confondu-header'>{t("about-gufaca")}</h3>
                            <p className='confondu-content'>
                                {t("paragraphe1-about")}
                            </p>
                            <ul>
                                <li data-aos="fade-up" data-aos-delay="100">
                                    <i class="bi bi-diagram-3 text-white"></i>
                                    <div>
                                        <h5 className='confondu-header'>{t("titre-service-1")}</h5>
                                        <p className='confondu-content'>{t("service-1-contenu")}</p>
                                    </div>
                                </li>
                                <li data-aos="fade-up" data-aos-delay="200">
                                    <i class="bi bi-fullscreen-exit text-white"></i>
                                    <div>
                                        <h5 className='confondu-header'>{t("titre-service-2")}</h5>
                                        <p className='confondu-content'>{t("service-2-contenu")}</p>
                                    </div>
                                </li>
                                <li data-aos="fade-up" data-aos-delay="300">
                                    <i class="bi bi-broadcast text-white"></i>
                                    <div>
                                        <h5 className='confondu-header'>{t("titre-service-3")}</h5>
                                        <p className='confondu-content'>{t("service-3-contenu")}</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>

                </div>
            </section>
            {/* <section id="about" class="about">
                <div class="container" data-aos="fade-up">

                    <div class="row gy-4">
                        <div class=" position-relative align-self-start text-center order-first">
                            <img src="https://i.ibb.co/9pY0M1n/aboutv.webp" alt="about" class="img-fluid" />
                        </div>
                        <div class="col-12 content order-last  ">
                            <h3 className='confondu-header text-center'>{t("about-gufaca")}</h3>
                            <ul>
                                <li data-aos=" fade-up" data-aos-delay="100">
                                    <div>
                                        <h5 className='text-center confondu-header'>{t("titre-service-1")}</h5>
                                        <div class="d-flex justify-content-center align-items-center">
                                            <i class="bi bi-diagram-3 text-white"></i>
                                            <p className='confondu-content text-center'>{t("service-1-contenu")}</p>
                                        </div>
                                    </div>
                                </li>
                                <li data-aos="fade-up" data-aos-delay="200">
                                    <div>
                                        <h5 className='text-center confondu-header'>{t("titre-service-2")}</h5>
                                        <div class="d-flex justify-content-center align-items-center">
                                            <i class="bi bi-fullscreen-exit text-white"></i>
                                            <p className='confondu-content text-center'>{t("service-2-contenu")}</p>
                                        </div>
                                    </div>
                                </li>
                                <li data-aos="fade-up" data-aos-delay="300">
                                    <div>
                                        <h5 className='text-center confondu-header'>{t("titre-service-3")}</h5>
                                        <div class=" d-flex justify-content-center align-items-center">
                                            <i class="bi bi-broadcast text-white"></i>
                                            <p className='confondu-content text-center'>{t("service-3-contenu")}</p>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>

                </div>
            </section> */}
            {/* <!-- Service Start --> */}
            <div class="container-xxl bg-light my-5 py-5">
                <div class="container py-5">
                    <div class="text-center mx-auto mb-5 wow fadeInUp">
                        <h1 class="display-6 mb-5">{t("Services")}</h1>
                        {/* <div class="d-inline-block rounded-pill bg-primary text-white py-1 px-3 mb-3">{t("title-service")}</div> */}
                    </div>
                    <div class="row g-4 justify-content-center">
                        <div class="col-lg-4 col-md-6 wow fadeInUp">
                            <div class="causes-item d-flex flex-column bg-white border-top border-5 border-primary rounded-top overflow-hidden h-100">
                                <div class="position-relative mt-auto">
                                    <img class="img-fluid" src="https://i.ibb.co/hySf5qD/technologiev-1.webp" alt="technologie" />
                                </div> 
                                <div class="text-center p-4 pt-0">
                                    <div class="d-inline-block bg-primary text-white rounded-bottom fs-5 pb-1 px-3 mb-4">
                                        {/* <small>{t("titre-service-1")}</small> */}
                                        <small>{t("titre-service-n")}</small>
                                    </div>
                                    <h6 class="mb-3">{t("r-commerce")}</h6>
                                    {/* <p>L'activité d’un bureau d’études consiste à vous assister et conseiller</p> */}
                                    <div class="causes-progress bg-light p-3 pt-2">
                                        <Link onClick={() => { window.scrollTo(0, 0) }} class="btn btn-outline-primary" to="/technologie">
                                            {t("main-button")}
                                            <div class="d-inline-flex btn-sm-square bg-primary text-white rounded-circle ms-2">
                                                <i class="bi bi-arrow-right"></i>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 wow fadeInUp">
                            <div class="causes-item d-flex flex-column bg-white border-top border-5 border-primary rounded-top overflow-hidden h-100">
                                <div class="position-relative mt-auto">
                                    <img class="img-fluid" src="https://i.ibb.co/w6wNdG8/sourcingv-1.webp" alt="sourcing" />
                                </div>
                                <div class="text-center p-4 pt-0">
                                    <div class="d-inline-block bg-primary text-white rounded-bottom fs-5 pb-1 px-3 mb-4">
                                        <small>{t("titre-service-2")}</small>
                                    </div>
                                    <h6 class="mb-3">{t("r-immobilier")}</h6>
                                    <div class="causes-progress bg-light p-3 pt-2">
                                        <Link onClick={() => { window.scrollTo(0, 0) }} class="btn btn-outline-primary" to="/sourcing">
                                            {t("main-button")}
                                            <div class="d-inline-flex btn-sm-square bg-primary text-white rounded-circle ms-2">
                                                <i class="bi bi-arrow-right"></i>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 wow fadeInUp">
                            <div class="causes-item d-flex flex-column bg-white border-top border-5 border-primary rounded-top overflow-hidden h-100">
                                <div class="position-relative mt-auto">
                                    <img class="img-fluid" src="https://i.ibb.co/HtrVzJK/negociationv.webp" alt="negociation" />
                                </div>
                                <div class="text-center p-4 pt-0">
                                    <div class="d-inline-block bg-primary text-white rounded-bottom fs-5 pb-1 px-3 mb-4">
                                        <small>{t("titre-service-3")}</small>
                                    </div>
                                    <h6 class="mb-3">{t("r-negociation")}</h6>
                                    <div class="causes-progress bg-light p-3 pt-2">
                                        <Link onClick={() => { window.scrollTo(0, 0) }} class="btn btn-outline-primary" to="/negociation">
                                            {t("main-button")}
                                            <div class="d-inline-flex btn-sm-square bg-primary text-white rounded-circle ms-2">
                                                <i class="bi bi-arrow-right"></i>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Service End --> */}



            <section id="call-to-action" class="call-to-action overlay2" style={{ backgroundImage: `url('https://i.ibb.co/2Mk52V5/immobilier-3.webp')`, backgroundPosition: "center" }}>
                <div class="container" data-aos="zoom-out">
                    <div class="row justify-content-center">
                        <div class="col-lg-8 text-center">
                            <h3>{t("question?")}</h3>
                            {/* <p> Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p> */}
                            <Link class="cta-btn" onClick={() => { window.scrollTo(0, 0) }} to="/contact">{t("contact")}</Link>
                        </div>
                    </div>

                </div>
            </section>

            <section id="faq" class="faq">
                <div class="container" data-aos="fade-up">

                    <div class="section-header">
                        <h2>{t("frequent-questions")}</h2>
                    </div>

                    <div class="row justify-content-center" data-aos="fade-up" data-aos-delay="200">
                        <div class="col-lg-10">

                            <div class="accordion accordion-flush" id="faqlist">
                                <div class="accordion-item">
                                    <h3 class="accordion-header">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-1">
                                            <i class="bi bi-question-circle question-icon"></i>
                                            {t("question1")}
                                        </button>
                                    </h3>
                                    <div id="faq-content-1" class="accordion-collapse collapse" data-bs-parent="#faqlist">
                                        <div class="accordion-body">
                                            {t("reponse1")}
                                        </div>
                                    </div>
                                </div>

                                <div class="accordion-item">
                                    <h3 class="accordion-header">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-2">
                                            <i class="bi bi-question-circle question-icon"></i>
                                            {t("question2")}
                                        </button>
                                    </h3>
                                    <div id="faq-content-2" class="accordion-collapse collapse" data-bs-parent="#faqlist">
                                        <div class="accordion-body">
                                            {t("reponse2")}
                                        </div>
                                    </div>
                                </div>

                                <div class="accordion-item">
                                    <h3 class="accordion-header">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-3">
                                            <i class="bi bi-question-circle question-icon"></i>
                                            {t("question3")}
                                        </button>
                                    </h3>
                                    <div id="faq-content-3" class="accordion-collapse collapse" data-bs-parent="#faqlist">
                                        <div class="accordion-body">
                                            {t("reponse3")}
                                        </div>
                                    </div>
                                </div>

                                <div class="accordion-item">
                                    <h3 class="accordion-header">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-4">
                                            <i class="bi bi-question-circle question-icon"></i>
                                            {t("question4")}
                                        </button>
                                    </h3>
                                    <div id="faq-content-4" class="accordion-collapse collapse" data-bs-parent="#faqlist">
                                        <div class="accordion-body">
                                            <i class="bi bi-question-circle question-icon"></i>
                                            {t("reponse4")}
                                        </div>
                                    </div>
                                </div>

                                <div class="accordion-item">
                                    <h3 class="accordion-header">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-5">
                                            <i class="bi bi-question-circle question-icon"></i>
                                            {t("question5")}
                                        </button>
                                    </h3>
                                    <div id="faq-content-5" class="accordion-collapse collapse" data-bs-parent="#faqlist">
                                        <div class="accordion-body">
                                            {t("reponse5")}
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h3 class="accordion-header">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-6">
                                            <i class="bi bi-question-circle question-icon"></i>
                                            {t("question6")}
                                        </button>
                                    </h3>
                                    <div id="faq-content-6" class="accordion-collapse collapse" data-bs-parent="#faqlist">
                                        <div class="accordion-body">
                                            {t("reponse6")}
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h3 class="accordion-header">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-7">
                                            <i class="bi bi-question-circle question-icon"></i>
                                            {t("question7")}
                                        </button>
                                    </h3>
                                    <div id="faq-content-7" class="accordion-collapse collapse" data-bs-parent="#faqlist">
                                        <div class="accordion-body">
                                            {t("reponse7")}
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h3 class="accordion-header">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-8">
                                            <i class="bi bi-question-circle question-icon"></i>
                                            {t("question8")}
                                        </button>
                                    </h3>
                                    <div id="faq-content-8" class="accordion-collapse collapse" data-bs-parent="#faqlist">
                                        <div class="accordion-body">
                                            {t("reponse8")}
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h3 class="accordion-header">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-9">
                                            <i class="bi bi-question-circle question-icon"></i>
                                            {t("question9")}
                                        </button>
                                    </h3>
                                    <div id="faq-content-9" class="accordion-collapse collapse" data-bs-parent="#faqlist">
                                        <div class="accordion-body">
                                            {t("reponse9")}
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>

                </div>
            </section>
        </main>
    );
}
