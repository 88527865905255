
import { motion } from "framer-motion";

const Transitions = ({ children }) => {
    return (
        <motion.div
           initial={{opacity:0}}
           animate={{opacity:1}}
           transition={{duration:0.8 }}
           exit={{opacity:0}}
        >
            {children}
        </motion.div>
    );
};
export default Transitions;