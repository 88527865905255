import 'flag-icon-css/css/flag-icons.min.css';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
export default function Contactservice() {
    const {t}=useTranslation()
    return (
        <div class="support-widget sidebar-widget wow fadeInLeft" data-wow-delay="00ms" data-wow-duration="1500ms">
            <div class="upper-box">
                <h3>{t("l-support-t")}</h3>
                <a className='fs-5 text-light' href="mailto:contact@gufaca.com">{t("contact")}: contact@gufaca.com</a>
            </div>
            <div class="lower-box">
                <h4>{t("l-support-1")}</h4>
                <div class="btn-box"><Link to="/contact" onClick={()=>{window.scrollTo(0, 0);}}><i class="bi bi-telephone-fill"></i>{t("contact-button")}</Link></div>
            </div>
        </div>
    );
}