import { useTranslation } from "react-i18next";
import LayoutService from "./LayoutService";
import Contactservice from "./Contactservice";
import Transitions from "./Transition";

export default function Sourcing() {
    const { t } = useTranslation()
    return (
        <Transitions>
            <main id="main">
                <section className=" d-flex align-items-center padding-top" style={{
                    background: `linear-gradient(to right, rgb(16, 18, 34,0.54) 0%, rgb(3, 18, 17,0.6)  100%),url('https://i.ibb.co/4FrX3kq/sourcing2.webp')`
                    , backgroundRepeat: "no-repeat", backgroundSize: "cover"
                }}>

                    <div className="container">
                        <div className="row gy-4 d-flex justify-content-between">
                            <div className="col-lg-6 order-2 order-lg-1 d-flex flex-column justify-content-center">
                                <h2 className="text-white fw-bold text-uppercase fs-2" style={{ letterSpacing: "0.2em" }}>{t("titre-service-2")}</h2>
                            </div>

                            <div className="">

                            </div>

                        </div>
                    </div>
                </section>
                <section class="single-service sidebar-page-container pt-lg-5 ps-lg-5 pe-lg-5 pb-0">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-4 col-md-12 col-sm-12 sidebar-side">
                                <div class="sidebar service-sidebar">
                                    <LayoutService />
                                    <Contactservice />
                                </div>
                            </div>
                            <div class="col-lg-8 col-md-12 col-sm-12 content-side">
                                <div class="single-service-content mt-4">
                                    <div class="content-style-one service-box">
                                        <div class="inner-box">
                                            <img className="img-fluid" src="https://i.ibb.co/0K2txg7/sourcingv.webp" alt="sourcing" />
                                            <p className="text-dark mt-1">
                                                {t("p1-immobilier")}
                                            </p>
                                            <p className="  text-dark">
                                                {t("p2-immobilier")}

                                            </p>
                                            <p className="text-dark   ">
                                                {t("p3-immobilier")}

                                            </p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="faq" class="faq pt-0">
                    <div class="container" data-aos="fade-up">

                        <div class="section-header">
                            <h2>{t("frequent-questions")}</h2>
                        </div>

                        <div class="row justify-content-center" data-aos="fade-up" data-aos-delay="200">
                            <div class="col-lg-10">

                                <div class="accordion accordion-flush" id="faqlist">
                                    <div class="accordion-item">
                                        <h3 class="accordion-header">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-5">
                                                <i class="bi bi-question-circle question-icon"></i>
                                                {t("question5")}
                                            </button>
                                        </h3>
                                        <div id="faq-content-5" class="accordion-collapse collapse" data-bs-parent="#faqlist">
                                            <div class="accordion-body">
                                                {t("reponse5")}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item">
                                        <h3 class="accordion-header">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-6">
                                                <i class="bi bi-question-circle question-icon"></i>
                                                {t("question6")}
                                            </button>
                                        </h3>
                                        <div id="faq-content-6" class="accordion-collapse collapse" data-bs-parent="#faqlist">
                                            <div class="accordion-body">
                                                {t("reponse6")}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item">
                                        <h3 class="accordion-header">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-7">
                                                <i class="bi bi-question-circle question-icon"></i>
                                                {t("question7")}
                                            </button>
                                        </h3>
                                        <div id="faq-content-7" class="accordion-collapse collapse" data-bs-parent="#faqlist">
                                            <div class="accordion-body">
                                                {t("reponse7")}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </section>
            </main>
        </Transitions>
    );
}