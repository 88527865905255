import React, { useState } from 'react'
import emailjs from 'emailjs-com';
import { useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Transitions from './Transition';
import ReCAPTCHA from 'react-google-recaptcha';
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';

export default function Contact() {
  const { t } = useTranslation();

  const form = useRef();
  const success = useRef();
  const [value, setValue] = useState()
  const sendEmail = () => {
    emailjs
      .sendForm(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_USER_ID
      )
      .then(
        (result) => {
          ///message de validation de reception
          success.current.textContent = t("success-message")
          success.current.className = "alert alert-success alert-dismissible"
          // setTimeout(() => {
          //   success.current.textContent = t("send-input")
          //   success.current.className = " btn btn-primary"
          // }, 3000)
          form.current.reset()
          setValue("+1")
          captchaRef.current.reset();
        },
        (error) => {
          success.current.textContent = t("echec-message")
          success.current.className = "alert alert-danger alert-dismissible"
          // setTimeout(() => {
          //   success.current.textContent = t("send-input")
          //   success.current.className = " btn btn-primary"
          // }, 3000)
          form.current.reset()
          setValue("+1")
          captchaRef.current.reset();

        }
      );
  };

  function onChange(value) {
    console.log('Captcha value:', value);
  }

  const captchaRef = useRef(null)

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  return (
    <Transitions>
      <main id="main">
        <div class="breadcrumbs">
          <div class="page-header d-flex align-items-center" style={{ backgroundImage: `url('https://i.ibb.co/09DXM09/page-header.webp')` }}>
            <div class="container position-relative">
              <div class="row d-flex justify-content-center">
                <div class="col-lg-12 text-center">
                  {/* <h6>Contact</h6> */}
                  <h2>{t("contact")}</h2>
                  {/* <p>Odio et unde deleniti. Deserunt numquam exercitationem. Officiis quo odio sint voluptas consequatur ut a odio voluptatem. Sit dolorum debitis veritatis natus dolores. Quasi ratione sint. Sit quaerat ipsum dolorem.</p> */}
                </div>
              </div>
            </div>

          </div>
          <nav>
            <div class="container">
              <ol>
                <li><Link to="/" onClick={() => { window.scrollTo(0, 0) }}>{t("home")}</Link></li>
                <li>Contact</li>
              </ol>
            </div>
          </nav>
        </div>

        <section id="contact" class="contact">
          <div class="container" data-aos="fade-up">

            <div>
              <iframe class="map-frame" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2796.498354832869!2d-73.64525428519555!3d45.50004567910134!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4cc919c45906281d%3A0x9f4ca370632d7d0f!2s4200%20Av.%20de%20Courtrai%2C%20Montr%C3%A9al%2C%20QC%20H3S%201C2%2C%20Canada!5e0!3m2!1sen!2sci!4v1676670200818!5m2!1sen!2sci" frameborder="0" allowfullscreen></iframe>
            </div>
            <div class="row gy-4 mt-4">

              <div class="col-lg-4">

                <div class="info-item d-flex">
                  <i class="bi bi-geo-alt flex-shrink-0"></i>
                  <div>
                    <h4>{t("f-adresse")}</h4>
                    <p>4200 Avenue de Courtai, Montréal, Québec, Canada</p>
                  </div>
                </div>
                <div class="info-item d-flex">
                  <i class="bi bi-envelope flex-shrink-0"></i>
                  <div>
                    <h4>Email</h4>
                    <p role='button'><a href="mailto:contact@gufaca.com" data-toggle="tooltip" data-placement="bottom" title="contact@gufaca.com">contact@gufaca.com</a></p>
                  </div>
                </div>
              </div>
              <div class="col-lg-8">
                {/* <form ref={form} onSubmit={sendEmail} role="form" class="php-email-form"> */}
                <form onSubmit={handleSubmit(sendEmail)} class="php-email-form" ref={form}>
                  <div class="row">
                    <div class="col-md-6 form-group">
                      {/* <input type="text" name="name" class="form-control" id="name" placeholder={t("input-name")} required /> */}
                      <input {...register('name', { required: true, pattern: /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u })} className="form-control" placeholder={t("input-name")} type="text" aria-invalid={errors.name ? "true" : "false"} />
                      {errors.name?.type === "required" && <small className='text-danger'>{t("error-required-name")}</small>}
                      {errors.name?.type === "pattern" && <small className='text-danger'>{t("error-pattern-name")}</small>}
                    </div>
                    <div class="col-md-6 form-group mt-3 mt-md-0">
                      {/* <input type="text" class="form-control" name="email" id="userEmail"
                        onChange={(y) => validateEmail(y)}
                        placeholder={t("input-email")} /> */}
                      <input type="text" class="form-control" {...register('email', { required: true, pattern: /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/ })} id="userEmail" aria-invalid={errors.email ? "true" : "false"} placeholder={t("input-email")} />
                      {errors.email?.type === "required" && <small className='text-danger'>{t("error-required-email")}</small>}
                      {errors.email?.type === "pattern" && <small className='text-danger'>{t("error-pattern-email")}</small>}

                    </div>
                    <div class="col-md-6 form-group mt-3 mt-md-0">
                      <PhoneInput
                      inputClass='phone'
                        
                        value={value}
                        onChange={setValue}
                        enableAreaCodes={true}
                        placeholder={t("input-phone")}
                        inputStyle={{
                          width: "100%",
                          height: "44px",
                          fontSize: "13px",
                          paddingLeft: "48px",
                          borderRadius: "5px"
                        }}
                        buttonStyle={{ borderRadius: "5px 0 0 5px" }}
                        dropdownStyle={{ width: "300px" }}
                        // country={'us'}
                        inputProps={{
                          name: 'phone',
                          required: true,
                          autoFocus: false
                        }}
                        defaultErrorMessage={'requis'}
                        isValid={(value, country) => {
                          if (value.match(/12345/)) {
                            return 'Invalid value: '+value+', '+country.name;
                          } else if (value.match(/1234/)) {
                            return false;
                          } else {
                            return true;
                          }
                        }}
                        
                      
                      />
                      {/* <input type="tel" class="form-control" name="phone" id="phone" placeholder={t("input-phone")} required /> */}
                    </div>
                    <div class="col-md-6 form-group mt-3 mt-md-0">
                      {/* <input type="text" class="form-control" name="company" id="phone" placeholder={t("input-compagnie")} /> */}
                      <input placeholder={t('input-compagnie')} className="form-control" {...register('company')} type="text" aria-invalid={errors.company ? "true" : "false"} />
                      {errors.company?.type==="required" && <small className='text-danger'>{t("error-required-compagnie")}</small>}
                    </div>
                  </div>
                  <div class="form-group mt-1">
                    {/* <input type="text" class="form-control" name="subject" id="subject" placeholder={t("input-object")} /> */}
                    <input placeholder={t('input-object')} className="form-control" {...register('subject', { required: true })} type="text" aria-invalid={errors.subject ? "true" : "false"} />
                    {errors.subject?.type==="required" && <small className='text-danger'>{t("error-required-subject")}</small>}
                  </div>
                  <div class="form-group mt-1">
                    {/* <textarea class="form-control" name="message" rows="5" placeholder={t("input-message")}></textarea> */}
                    <textarea placeholder={t("input-message")} rows="5" className="form-control" {...register('message', { required: "Le message est requis" })} aria-invalid={errors.message ? "true" : "false"} />
                    {errors.message?.type==="required" && <small className='text-danger'>{t("error-required-message")}</small>}
                  </div>
                  <div class="my-3">
                    <div class="loading">{t("chargement")}</div>
                    <div class="error-message"></div>
                    <div class="sent-message">{t("success")}</div>
                  </div>
                  {/* <UncontrolledAlert color="success">
                    <div class="text-center" ref={success}></div>
                  </UncontrolledAlert> */}
                  {/* <div class="text-center" ref={success}></div> */}
                  <div class="" role="alert">
                    <div ref={success}>
                    </div>
                  </div>
                  <div class="px-g">
                    <ReCAPTCHA
                      sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
                      onChange={onChange}
                      ref={captchaRef}
                    />
                  </div>
                  <div className='text-center mt-2'><button type="submit">{t("send-input")}</button></div>
                </form>
              </div>

            </div>

          </div>
        </section>

      </main>
    </Transitions>
  );

}