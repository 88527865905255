import { NavLink } from "react-router-dom";
import "../App.css"
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/css/bootstrap.min.css"
import { useTranslation } from "react-i18next";
import {
    useLocation
} from 'react-router-dom';
export default function LayoutService() {
    const { t } = useTranslation();
    const location = useLocation();
    const direction = () => {
        window.scrollTo(0, 0);
    }
    return (

        <div class="categories-widget sidebar-widget wow fadeInLeft" data-wow-delay="00ms" data-wow-duration="1500ms">
            <h3 class="widget-title">{t("all-services")}</h3>
            <ul class="categories-list">
                <li onClick={() => {
                    window.scrollTo(0, 0);
                }} role="button"><NavLink className={location.pathname === "/services" || location.pathname === "/technologie" ? "text-primary" : "text-dark"} to="/technologie">{t("titre-service-1")}</NavLink></li>
                <li onClick={() => {
                    window.scrollTo(0, 0);
                }} role="button" className={location.pathname === "/immobilier" ? " " : "null"} ><NavLink to="/sourcing" className={location.pathname === "/sourcing" ? "text-primary" : "text-dark"}>{t("titre-service-2")}</NavLink></li>
                <li onClick={direction} role="button" className={location.pathname === "/negociation" ? " " : "null"}><NavLink className={location.pathname === "/negociation" ? "text-primary" : "text-dark"} to="/negociation" >{t("titre-service-3")}</NavLink></li>
            </ul>
        </div>
    );
}