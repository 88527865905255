import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Transitions from "./Transition";
export default function Services() {
  const { t } = useTranslation()
  return (
    <Transitions>
    <main id="main">
      <div class="breadcrumbs">
        <div class="page-header d-flex align-items-center" style={{ backgroundImage: `url("/assets/img/page-header.jpg")` }}>
          <div class="container position-relative">
            <div class="row d-flex justify-content-center">
              <div class="col-lg-12 text-center">
                <h6></h6>
                <h2>{t("service")}</h2>
              </div>
            </div>
          </div>

        </div>
        <nav>
          <div class="container">
            <ol>
              <li><Link to="/" onClick={()=>{window.scrollTo(0,0)}}>{t("home")}</Link></li>
              <li>{t("service")}</li>
            </ol>
          </div>
        </nav>
      </div>

        <div class="container-xxl bg-light my-5 py-5">
                <div class="container py-5">
                    <div class="text-center mx-auto mb-5 wow fadeInUp">
                        <div class="d-inline-block rounded-pill bg-primary text-white py-1 px-3 mb-3">{t("Services")}</div>
                        <h1 class="display-6 mb-5">{t("title-service")}</h1>
                    </div>
                    <div class="row g-4 justify-content-center">
                        <div class="col-lg-4 col-md-6 wow fadeInUp">
                            <div class="causes-item d-flex flex-column bg-white border-top border-5 border-primary rounded-top overflow-hidden h-100">
                                <div class="position-relative mt-auto">
                                    <img class="img-fluid" src="/images/service-immobilier.jpg" alt=""/>
                                </div>
                                <div class="text-center p-4 pt-0">
                                    <div class="d-inline-block bg-primary text-white rounded-bottom fs-5 pb-1 px-3 mb-4">
                                        <small>{t("titre-service-2")}</small>
                                    </div>
                                    <h6 class="mb-3">{t("r-immobilier")}</h6>
                                    <div class="causes-progress bg-light p-3 pt-2">
                                        <Link onClick={()=>{window.scrollTo(0,0)}} class="btn btn-outline-primary" to="/immobilier">
                                            {t("main-button")}
                                            <div class="d-inline-flex btn-sm-square bg-primary text-white rounded-circle ms-2">
                                                <i class="bi bi-arrow-right"></i>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 wow fadeInUp">
                            <div class="causes-item d-flex flex-column bg-white border-top border-5 border-primary rounded-top overflow-hidden h-100">
                                <div class="position-relative mt-auto">
                                    <img class="img-fluid" src="assets/img/cargo-service.jpg" alt=""/>
                                </div>
                                <div class="text-center p-4 pt-0">
                                    <div class="d-inline-block bg-primary text-white rounded-bottom fs-5 pb-1 px-3 mb-4">
                                        <small>{t("titre-service-1")}</small>
                                    </div>
                                    <h6 class="mb-3">{t("r-commerce")}</h6>
                                    {/* <p>L'activité d’un bureau d’études consiste à vous assister et conseiller</p> */}
                                    <div class="causes-progress bg-light p-3 pt-2">
                                        <Link onClick={()=>{window.scrollTo(0,0)}} class="btn btn-outline-primary" to="/commerce">
                                        {t("main-button")}
                                            <div class="d-inline-flex btn-sm-square bg-primary text-white rounded-circle ms-2">
                                                <i class="bi bi-arrow-right"></i>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 wow fadeInUp">
                            <div class="causes-item d-flex flex-column bg-white border-top border-5 border-primary rounded-top overflow-hidden h-100">
                                <div class="position-relative mt-auto">
                                    <img class="img-fluid" src="assets/img/trucking-service.jpg" alt=""/>
                                </div>
                                <div class="text-center p-4 pt-0">
                                    <div class="d-inline-block bg-primary text-white rounded-bottom fs-5 pb-1 px-3 mb-4">
                                        <small>{t("titre-service-3")}</small>
                                    </div>
                                    <h6 class="mb-3">{t("r-negociation")}</h6>
                                    <div class="causes-progress bg-light p-3 pt-2">
                                        <Link onClick={()=>{window.scrollTo(0,0)}} class="btn btn-outline-primary" to="/negociation">
                                            {t("main-button")}
                                            <div class="d-inline-flex btn-sm-square bg-primary text-white rounded-circle ms-2">
                                                <i class="bi bi-arrow-right"></i>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
    </main>
    </Transitions>
  );
}
