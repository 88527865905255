import React from 'react'
import Slide from "./Slide";
import BodyHome from "./BodyHome";
import Transitions from '../Transition';

export default function Home() {

    return (
        <Transitions>
        
            <Slide/>
            <BodyHome/>
        
        </Transitions>
    );

}